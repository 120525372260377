<template>
  <div class="app-container">
    <!-- <div style="height: 5rem;"></div> -->
    <div class="">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import eruda from "eruda"
export default {
  methods: {
    init(){
      this.addErudaMobileDebug();
    },
    addErudaMobileDebug(){
      eruda.init();
    }
  },
};
window.onload = function () {
  document.addEventListener("touchstart", function (e) {
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (e) {
    e.preventDefault();
  });
};
</script>
