import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = [
    // 登录
    {
        path: '/',
        name: 'Login',
        component: () =>
            import ('../components/page/Login.vue')
    },
    // 登录
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../components/page/Login.vue')
    },
    // 首页
    {
        path: '/index',
        name: 'Index',
        component: () =>
            import ('../components/page/index.vue')
    },
    // 注册成功
    {
        path: '/registerWin',
        name: 'RegisterWin',
        component: () =>
            import ('../components/page/registerWin.vue')
    },
    // 注册
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import ('../components/page/register.vue'),
        meta: { title: '商家自主注册' }
    },
    // 商家自主找回密码
    {
        path: '/updatePass',
        name: 'UpdatePass',
        component: () =>
            import ('../components/page/updatePass.vue'),
        meta: { title: '商家找回密码' }
    },
    // 产品库列表
    {
        path: '/product',
        name: 'Product',
        component: () =>
            import ('../components/page/product.vue'),
        meta: { title: '产品库' }
    },
    // 创建产品
    {
        path: '/addproduct',
        name: 'Addproduct',
        component: () =>
            import ('../components/subassembly/addproduct.vue'),
        meta: { title: '创建产品' }
    },
    // 品牌库列表
    {
        path: '/brand',
        name: 'Brand',
        component: () =>
            import ('../components/page/brand.vue'),
        meta: { title: '品牌库' }
    },
    // 产品提报库列表
    {
        path: '/productAudit',
        name: 'ProductAudit',
        component: () =>
            import ('../components/page/productAudit.vue'),
        meta: { title: '产品提报库' }
    },
    // 创建品牌
    {
        path: '/addBrand',
        name: 'AddBrand',
        component: () =>
            import ('../components/subassembly/addBrand.vue'),
        meta: { title: '创建品牌' }
    },
    // 产品详情
    {
        path: '/productDetails',
        name: 'ProductDetails',
        component: () =>
            import ('../components/subassembly/productDetails.vue'),
        meta: { title: '审过产品' }
    },
    // 待审通过产品详情
    {
        path: '/productDetails1',
        name: 'ProductDetails1',
        component: () =>
            import ('../components/subassembly/productDetails1.vue'),
        meta: { title: '待审产品' }
    },
    // 编辑产品
    {
        path: '/compileProduct',
        name: 'CompileProduct',
        component: () =>
            import ('../components/subassembly/compileProduct.vue'),
        meta: { title: '编辑产品' }
    },
    // 联系人列表
    {
        path: '/linkmanPerson',
        name: 'LinkmanPerson',
        component: () =>
            import ('../components/subassembly/linkmanPerson.vue'),
        meta: { title: '联系人列表' }
    },
    // 商家信息列表
    {
        path: '/merchantInfo',
        name: 'MerchantInfo',
        component: () =>
            import ('../components/subassembly/merchantInfo.vue'),
        meta: { title: '商家信息' }
    },
    // 编辑商家信息
    {
        path: '/updateMerchantInfo',
        name: 'UpdateMerchantInfo',
        component: () =>
            import ('../components/subassembly/updateMerchantInfo.vue'),
        meta: { title: '编辑商家信息' }
    },
    // 商家收款信息
    {
        path: '/collectionInfo',
        name: 'CollectionInfo',
        component: () =>
            import ('../components/subassembly/collectionInfo.vue'),
        meta: { title: '商家收款信息' }
    },
    // 添加收款信息
    {
        path: '/addCollectionInfo',
        name: 'AddCollectionInfo',
        component: () =>
            import ('../components/subassembly/addCollectionInfo.vue'),
        meta: { title: '添加收款信息' }
    },
    // 编辑收款信息
    {
        path: '/updateCollectionInfo',
        name: 'UpdateCollectionInfo',
        component: () =>
            import ('../components/subassembly/updateCollectionInfo.vue'),
        meta: { title: '编辑收款信息' }
    },
    // 新增-编辑联系人
    {
        path: '/updateAdmin',
        name: 'UpdateAdmin',
        component: () =>
            import ('../components/subassembly/updateAdmin.vue'),
        meta: { title: '新增-编辑联系人' }
    },
    // 商家修改密码
    {
        path: '/setPass',
        name: 'SetPass',
        component: () =>
            import ('../components/subassembly/setPass.vue'),
        meta: { title: '商家修改密码' }
    },
    //  我的
    {
        path: '/mine',
        name: 'Mine',
        component: () =>
            import ('../components/page/mine.vue'),
        meta: { title: '我的' }
    },
    // 品牌基础信息（审核通过）
    {
        path: '/brandDetalis',
        name: 'BrandDetalis',
        component: () =>
            import ('../components/subassembly/brandDetalis.vue'),
        meta: { title: '品牌库详情' }
    },
    // 品牌基础信息（待审通过）
    {
        path: '/brandDetalis1',
        name: 'BrandDetalis1',
        component: () =>
            import ('../components/subassembly/brandDetalis1.vue'),
        meta: { title: '品牌库详情' }
    },
    // 编辑品牌
    {
        path: '/updateBrand',
        name: 'UpdateBrand',
        component: () =>
            import ('../components/subassembly/updateBrand.vue'),
        meta: { title: '编辑品牌' }
    },
    // 已提报产品基础信息
    {
        path: '/auditProductDetails',
        name: 'auditProductDetails',
        component: () =>
            import ('../components/subassembly/auditProductDetails.vue'),
        meta: { title: '提报产品信息' }
    },
    // 已提报产品套餐信息
    {
        path: '/auditSpecificationMessage',
        name: 'AuditSpecificationMessage',
        component: () =>
            import ('../components/subassembly/auditSpecificationMessage.vue'),
        meta: { title: '提报产品规格' }
    },
    // 产品套餐展示
    {
        path: '/showAuditSpecificationMessage',
        name: 'ShowAuditSpecificationMessage',
        component: () =>
            import ('../components/subassembly/showAuditSpecificationMessage.vue'),
        meta: { title: '产品套餐展示' }
    },
    // 提报产品套餐信息
    {
        path: '/applyProductCombo',
        name: 'ApplyProductCombo',
        component: () =>
            import ('../components/subassembly/applyProductCombo.vue'),
        meta: { title: '提报套餐信息' }
    },
    // 提报产品基本信息
    {
        path: '/applyProductBasic',
        name: 'ApplyProductBasic',
        component: () =>
            import ('../components/subassembly/applyProductBasic.vue'),
        meta: { title: '提报基本信息' }
    },
    {
        path: '*',
        name: '/404',
        component: () =>
            import ('../components/page/404.vue'),
    }
]
const router = new Router({
    routes
})
export default router