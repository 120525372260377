import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import store from '../store/index.js'
Vue.prototype.axios = axios
Vue.prototype.qs = qs
import { Notify } from 'vant'

function ajax(url, params, method, headers = {}, isHideLoading = '') {
    return new Promise((resolve, reject) => {
        // 判断是headers对象中是否有userid
        if (!headers.hasOwnProperty('userId')) {
            headers['userId'] = store.state.channelId; // 商户id
        }
        // headers['clientType'] = 0; //0.公众号 1小程序
        // headers['beginTime'] = store.state.beginTime; //访问时间
        // headers['userType'] = store.state.userType; //用户类型
        headers['content-type'] = 'application/json;charset=utf-8'


        var http = {}
        if (method == 'post' || method == 'POST') {
            http = {
                method: 'post',
                data: JSON.stringify(params)
            }
        } else if (method == 'delete') {
            http = {
                method: 'delete',
                params,
            }
        } else if (method == 'put') {
            http = {
                method: 'put',
                data: JSON.stringify(params)
            }
        } else {
            http = {
                method: 'get',
                params,
            }
        }

        http.url = store.state.httpUrl + url
        if (url.indexOf('http') != -1) {
            http.url = url
        }
        headers['content-type'] = 'application/json;charset=utf-8'

        http.headers = headers
        http.timeout = 1000 * 6 * 10

        // if (isHideLoading == '') {
        //     store.commit('setLoading', true)
        // }
        // var loginTime = localStorage.getItem('loginTime');
        // var now = new Date().getTime();
        // if (url.indexOf('/merchant/getVerifyCode') == -1 && url.indexOf('/merchant/checkVerifyCode') == -1 && url.indexOf('/merchant/setPwd') == -1 && url.indexOf('/channelBusiness/wechatAuth') == -1) {
        //     if (url.indexOf('/getBusinessLicense') == -1 && url.indexOf('/merchantRegister') == -1) {
        //         if (url.indexOf('/merchant/login') == -1 && url.indexOf('/merchantOtherContact/listMerchantBranchs') == -1) {
        //             var loginTime = localStorage.getItem('loginTime');
        //             var now = new Date().getTime();

        //             // if (loginTime == null || now > Number(loginTime) + 1000 * 60 * 60 * 24 * 7) {
        //             //     // if(loginTime == null||now>Number(loginTime)+10000){
        //             //     // if(loginTime == null||now>Number(loginTime)+10000){
        //             //     Notify({ type: 'danger', message: '登录已过期,请重新登录' });
        //             //     localStorage.clear();
        //             //     if (window.location.host.indexOf('http') == -1) {
        //             //         window.location.href = 'http://' + window.location.host + '/#/';
        //             //     } else {
        //             //         window.location.href = window.location.host + '/#/';
        //             //     }
        //             //     return false
        //             // }
        //         }
        //     }
        // }
        axios(http).then(res => {
                var res_code = res.status.toString();
                if (res_code.charAt(0) == 2 && res.data) {
                    resolve(res.data);
                } else {
                    Message.error('服务器响应异常')
                    reject('服务响应失败=>' + res_code)
                }
            })
            .catch(error => {
                Notify({ type: 'danger', message: error });
                reject('失败=>' + error)

            })
    })
}


export default { ajax };