import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import VueCookies from 'vue-cookies'


let config;
if (window.location.host.indexOf('quanxizhiyan') != -1) {

    config = {
        httpUrl: 'https://api.quanxizhiyan.com',
        appid: 'wx4c42aa5b961b6e4f', //供应链 公众号
        imgUpdateUrl: 'https://admin.quanxizhiyan.com',
    }
} else if (window.location.host.indexOf('10.13.1.204') != -1) {
    config = {
        httpUrl: 'http://10.13.1.204:8764',
        appid: 'wxd1a30ae4b41ea307', //供应链 公众号0
        imgUpdateUrl: 'http://10.13.1.204:8099',
    }
} else {
    config = {
        httpUrl: 'https://api.lianlianyouxuan.net',
        appid: 'wxd1a30ae4b41ea307', //供应链 公众号
        imgUpdateUrl: 'https://admin.lianlianyouxuan.net',
    }
}
const state = {
    appid: config.appid,
    httpUrl: config.httpUrl,
    imgUpdateUrl: config.imgUpdateUrl,

    // 用户id（预览商品详情时需要）
    userid: 'preview',

    // 商家id 1206481881583976448
    merchantId: window.localStorage.getItem('merchantId') || '',

    // 联系人id
    operator: window.localStorage.getItem('operator') || '',
    // 部门id
    branchId: window.localStorage.getItem('branchId') || '',

    // 门店列表
    mendians: [],

    isEfs: VueCookies.get('isEfs') || 0, //到家电子面单权限 0无 1有

    // 到店权限
    resources: VueCookies.get('resources') || '',


    regTel: /^1[3,4,5,6,7,8,9]\d{9}$/,
    regCode: /^\d{6}$/,
    posswordReg: /^(\w){6,18}$/, //只能输入6-18位字母、数字、下划线

    // 订单状态
    orderStates: [{
            id: 0,
            val: '待支付'
        },
        {
            id: 1,
            val: '待发货'
        },
        {
            id: 2,
            val: '已发货'
        },
        {
            id: 3,
            val: '交易完成'
        },
        {
            id: 4,
            val: '交易关闭'
        }
    ],
    ranges: [{
            name: '订单发货',
        },
        {
            name: '合同结算'
        }
    ],
    // 联联-合同收款账户种类
    accountStatus: [{
            name: '公司公户',
        },
        {
            name: '法人账户',
        },
        {
            name: '其它账户',
        },
    ],

    // 是否外输状态
    isExport: [{
            name: '允许'
        },
        {
            name: '不允许'
        },
        {
            name: '不确定'
        },
    ],
    // 品牌经营类型
    brandLicenseScope: [{
            name: '自有品牌'
        },
        {
            name: '一级授权'
        },
        {
            name: '多级授权'
        }
    ],
    // 品牌经营类型
    brandType: [
        { name: 1 },
        { name: 2 },
        { name: 3 },
        { name: 4 },
        { name: 5 },
        { name: 6 },
        { name: 7 },
        { name: 8 },
        { name: 9 },
        { name: 10 },
        { name: 11 },
        { name: 12 },
        { name: 13 },
        { name: 14 },
        { name: 15 },
        { name: 16 },
        { name: 17 },
        { name: 18 },
        { name: 19 },
        { name: 20 },
        { name: 21 },
        { name: 22 },
        { name: 23 },
        { name: 24 },
        { name: 25 },
        { name: 26 },
        { name: 27 },
        { name: 28 },
        { name: 29 },
        { name: 30 },
        { name: 31 },
        { name: 32 },
        { name: 33 },
        { name: 34 },
        { name: 35 },
        { name: 36 },
        { name: 37 },
        { name: 38 },
        { name: 39 },
        { name: 40 },
        { name: 41 },
        { name: 42 },
        { name: 43 },
        { name: 44 },
        { name: 45 },
    ],
}

const mutations = {
    setColor(state, color) {
        state.color = color
    },

    saveMerchantId(state, merchantId) {
        state.merchantId = merchantId || ''
    },
    merchantId(state, merchantId) {
        state.merchantId = merchantId || ''
    },

    saveIsEfs(state, isEfs) {
        state.isEfs = isEfs || 0
    },

    saveOperator(state, operator) {
        state.operator = operator || ''
    },

    saveBranchId(state, branchId) {
        state.branchId = branchId || ''
    },

    saveServiceType(state, serviceType) {
        state.serviceType = serviceType
    },

    saveMendians(state, mendians) {
        state.mendians = mendians
    },

    saveResources(state, resources) {
        state.resources = resources
    }
}

export default new Vuex.Store({
    state,
    mutations
})