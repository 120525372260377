import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueCookies from "vue-cookies";
import ElementUI from 'element-ui';
import './assets/css/main.css';
import './assets/css/detailsStyle.css'
import './assets/css/updateDate.css'
import './assets/css/system.css'
import 'element-ui/lib/theme-chalk/index.css';
import './components/subassembly/emoji-regex.js'
import emoji from './components/subassembly/emoji-regex.js';
import { Dialog } from 'vant';
import { Lazyload } from 'vant';
import { Notify } from "vant";
import { getQueryVariable } from '../static/js/comm.js'
Vue.use(ElementUI);
Vue.use(Lazyload);
Vue.use(Notify);

comm();
Vue.directive('emoji', emoji)
    // 移动端调式工具
import Vconsole from 'vconsole';
if (window.location.host.indexOf('quanxizhiyan') == -1 && window.location.host.indexOf('quanxizhiyan') == -1) {
    if (window.location.host.indexOf('quanxizhiyan') == -1) {
        new Vconsole();
        window.onload = function() {
            document.querySelector('.vc-switch').innerHTML = '全息之眼调试'
        }
    }
}
Vue.use(VueCookies);

Vue.use(Vant);
Vue.config.productionTip = false

// md5
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

// 接口封装
import http from './api/http.js'
Vue.prototype.$http = http


// 时间戳转换为标准时间
function formatDate(date, fmt) {
    let o = {
        'M+': date.getMonth() + 1, //月份
        　　'd+': date.getDate(), //日
        　　'h+': date.getHours(), //小时
        　　'm+': date.getMinutes(), //分  
        　　's+': date.getSeconds(), //秒
        　　"q+": Math.floor((date.getMonth() + 3) / 3), //季度   
        "S": date.getMilliseconds() //毫秒   
    };
    if (/(y+)/.test(fmt)) { //年份
        　　
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));　　
        }
    }
    return fmt;
};
Vue.prototype.formatDate = formatDate

// 中国标准时间转yyyy-mm-dd hh:ii:ss
function formatDate2(date) {
    let d = new Date(date)
    let datetime = d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) + ' ' + (d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) + ':' + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) + ':' + (d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds());
    return datetime
}
Vue.prototype.formatDate2 = formatDate2

// 中国标准时间转yyyy-mm-dd
function formatDate3(date) {
    let d = new Date(date)
    let datetime = d.getFullYear() + '-' + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + '-' + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
    return datetime
}
Vue.prototype.formatDate3 = formatDate3

// base64编码
// let Base64 = require('js-base64').Base64
// Vue.prototype.Base64 = Base64
// import store from './store/index'

// 图片懒加载
// import VueLazyload from "vue-lazyload";
// Vue.use(VueLazyload);
// Vue.use(VueLazyload, {
//         error: require('./assets/img/errorImg.webp'),
//         loading: require('./assets/img/imgLoadding.gif')
//     })
// import VueLazyLoad from 'vue-lazyload'

function comm() {
    let code = getQueryVariable('code') || ''
    if (code == "") {
        // 第一步: 获取code
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + store.state.appid + "&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_base#wechat_redirect"
    } else {
        http.ajax('/ffkj-main/merchant/loginAuth2', {
            code: code
        }, 'get', {}).then(res => {
            if (res.code == 0) {
                window.localStorage.openId = res.data.openId;
                // 跳登录
                if (res.data.isAuth == false) {
                    getHTML();
                    router.replace({
                        path: '/login',
                        query: {
                            oid: res.data.openId || ''
                        }
                    })
                }
                // 免登录
                else {
                    getURL();
                    window.localStorage.setItem("merchantId", res.data.merchantId);
                    if (
                        localStorage.getItem("merchantId") != null ||
                        localStorage.getItem("merchantId") != undefined
                    ) {
                        $cookies.set(
                            "ms_username",
                            localStorage.getItem("ms_username"),
                            7 * 24 * 60 * 60
                        );
                    }

                    localStorage.setItem("loginTime", new Date().getTime()); //重置时间

                    // 1到家 2到店
                    $cookies.set(
                        "serviceType",
                        res.data.serviceType,
                        24 * 60 * 60
                    );
                    store.commit("saveServiceType", res.data.serviceType);

                    store.commit("saveIsEfs", res.data.isEfs || 0);
                    $cookies.set("isEfs", res.data.isEfs || 0, 24 * 60 * 60);

                    store.commit("saveResources", res.data.resources || "");
                    $cookies.set(
                        "resources",
                        res.data.resources || "",
                        24 * 60 * 60
                    );
                    $cookies.set(
                        "ms_username",
                        (res.data.merchantName || "") +
                        " - " +
                        (res.data.contacts || ""),
                        800 * 24 * 60 * 60
                    );

                    window.localStorage.setItem("operator", res.data.id || "");
                    store.commit("saveOperator", res.data.id || "");
                    window.localStorage.setItem("branchId", res.data.branchId || "");
                    store.commit("saveBranchId", res.data.branchId || "");
                    window.localStorage.setItem(
                        "ms_username",
                        (res.data.merchantName || "") +
                        " - " +
                        (res.data.contacts || "")
                    );
                    window.localStorage.setItem("merchantId", res.data.merchantId);
                    store.commit("merchantId", res.data.merchantId);
                    store.commit("saveMerchantId", res.data.merchantId);
                    $cookies.set(
                        "ms_phone",
                        res.data.phone,
                        7 * 24 * 60 * 60
                    );
                    window.localStorage.setItem('ms_phone', res.data.phone || '');
                    getHTML();
                    if (localStorage.getItem("UID") != 'null') {
                        router.replace({
                            path: '/applyProductBasic',
                            query: {
                                oid: res.data.openId || ''
                            }
                        })

                    } else {
                        router.replace({
                            path: '/productAudit',
                            query: {
                                oid: res.data.openId || ''
                            }
                        })
                    }
                }
            } else {
                getHTML();
                if (!(res.code == 20000)) {
                    Notify({ type: "danger", message: res.message });
                }
            }
        })
    }
}

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    if (to.path == '/registerWin' || to.path == '/register' || to.path == '/updatePass') {
        next()
    } else if (!localStorage.getItem('merchantId') && to.path != '/login') {
        next('/login');
    } else {
        next()
    }
})

function getURL() {
    var test = window.location.href;
    if (test.indexOf('UID') != -1) {
        if (test.indexOf("?") != -1) {
            var testArr = test.split("?");
            var UID = testArr[1].split("=");
            if (
                UID[0] != null ||
                (UID[0] != undefined && UID[1] != "") ||
                UID[1] != null
            ) {
                if (UID[1].indexOf("#") || UID[1].indexOf("&")) {
                    UID[1] = UID[1].replace("#", "");
                    UID = UID[1].split("&");
                }
                window.localStorage.setItem("UID", UID[0] || "");
            }
        }
    } else {
        window.localStorage.setItem("UID", null);
    }
}



function getHTML() {
    new Vue({
        store,
        router,
        render: h => h(App)
    }).$mount('#app')
}